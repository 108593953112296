<template>
  <div class="package-page shared-index">
    <el-row class="table-title" type="flex" align="center" justify="space-between">
      <div class="table-title__left">
        <el-tabs v-model="activeTab" type="card" @tab-click="handleReloadData">
          <el-tab-pane name="pages">
            <div slot="label">
              Danh sách trang Banner
              <span v-if="activeTab === 'pages'">({{ total }})</span>
            </div>
          </el-tab-pane>
        </el-tabs>
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          size="small"
          @click="formVisible = true"
        >Thêm</el-button>
        <el-button
          type="primary"
          icon="el-icon-refresh-right"
          size="small"
          @click="handleReloadData"
        >Làm mới</el-button>
      </div>
      <div class="table-title__right">
        <el-input
          v-model="searchText"
          suffix-icon="el-icon-search"
          placeholder="Tìm kiếm theo tên"
          size="small"
          @input="handleSearch"
        ></el-input>
      </div>
    </el-row>
    <list-pages
      v-loading="callingAPI"
      :list-pages="listPages"
      @updatePage="updatePage"
      @reload="loadData"
    ></list-pages>
    <el-row type="flex" align="middle" justify="end" class="pagination">
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 20, 30]"
        :page-size.sync="limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleChangeSize"
        @current-change="handleChangePage"
      ></el-pagination>
    </el-row>
    <form-popup
      :visible="formVisible"
      @close="formVisible = false"
      @reload="handleReloadData"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ListPages from './components/List.vue'
import FormPopup from './components/popups/Form.vue'

import { getBanners } from '@/services/banner'
export default {
  name: 'Pages',
  components: {
    ListPages,
    FormPopup
  },
  data() {
    return {
      formVisible: false,
      callingAPI: false,
      activeTab: 'pages',
      searchText: '',
      page: 1,
      limit: 10,
      total: 0,
      listPages: [],
      selectedPage: {}
    }
  },
  computed: {
    ...mapGetters(['language']),
    params() {
      const p = {
        page: this.page,
        per_page: this.limit,
        locale: this.language
      }
      return p
    }
  },
  watch: {
    language: 'loadData'
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.callingAPI = true
      this.formVisible = false
      getBanners(this.params).then((response) => {
        this.listPages = response.result
        this.total = response.total
      }).catch((e) => {
        this.listPages = []
        this.total = []
      })
      this.callingAPI = false
    },

    handleReloadData() {
      this.searchText = ''
      this.page = 1
      this.loadData()
    },

    handleChangePage(page) {
      this.page = page
      this.loadData()
    },

    handleChangeSize(size) {
      this.limit = size
      this.page = 1
      this.loadData()
    },

    handleSearch(text) {
      this.searchText = text
      this.page = 1
      this.loadData()
    },

    updatePage(data) {
      this.selectedPage = data
      this.formVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

